<template>
  <div class="tjEditor tj-editor">
    <label class="tj-editor__custom-label" v-if="customLabel && customLabel.length > 0">{{ customLabel }}</label>
    <form @submit.prevent="createNote" role="form">
      <!-- text area with Add to note placeholder -->
      <!-- <div class="tj-editor__editor">
        <mavon-editor
          rows="6"
          id="note-input"
          placeholder="Add to note..."
          class="tj-editor__text"
          :showShortCut="false"
          v-model="customNote"
          :language="'en'"
          :subfield="false"
          :toolbars="mavonToolbar"
          @save="createNote"
        />
        <div class="tj-editor__notification">
          <span
            class="spinner-border spinner-border-sm ml-2 tj-editor__notification--icon"
            :style="{ opacity: isEddieLoading ? 1 : 0 }"
          ></span>
          <span
            class="tj-editor__notification--title"
            :style="{ opacity: eddieNotification.length > 0 ? 1 : 0 }"
            >Eddie is loading your content</span
          >
        </div>
      </div> -->
      <div class="d-grid mt-2">
        <Editor :onContentChange="onContentChange"/>
      </div>
      <div class="d-grid mt-2">
        <button class="ed-btn__blim ed-btn__sm" type="submit">
          <span v-if="!isLoading && customLabel && customLabel.length > 0">Evaluate</span>
          <span v-else-if="!isLoading"> Add note </span>
          <span
            v-else
            class="spinner-border spinner-border-sm ml-2"
            :style="{ opacity: isLoading ? 1 : 0 }"
          ></span>
        </button>
      </div>
      <div class="d-grid mt-2" v-html="review"></div>
    </form>
  </div>
</template>

<script>
import moment from 'moment';
import Editor from '../../components/Editor.vue';
// import mavenEditorConfigs from '@/config/mavenEditor';

export default {
  components: {
    Editor,
  },
  data() {
    return {
      customNote: '',
      isLoading: false,
      // mavonToolbar: mavenEditorConfigs.mavonToolbar,
      isEddieStarted: false,
      isEddieEnded: false,
      isEddieLoading: false,
      eddieNotification: '',
      eddieQuery: '',
      goal: null,
      customLabel: '',
      review: '',
    };
  },
  computed: {
    isGameConnected() {
      // console.log('xvf', 'is Game connected', this.$store.state.game.connected);
      return this.$store.state.game.connected;
    },
  },
  methods: {
    onContentChange(value) {
      this.customNote = value;
    },
    async createNote() {
      this.isLoading = true;
      const value = {
        note: this.customNote,
        noteUrl: 'thoughtjumper-client.netlify.app/',
        type: 'note',
        timestamp: moment().format(),
      };

      await this.$store.dispatch('addToNote', value);
      if (this.customLabel && this.customLabel.length > 0) {
        await this.evaluateAnswer(this.customLabel, this.customNote);
      }
      this.customNote = '';
      this.isLoading = false;
    },
    async evaluateAnswer(question, note) {
      const payload = {
        question,
        note,
      };
      this.review = '';
      const assessmentPayload = {
        label: question,
        type: 'SUBJECTIVE',
        status: 'PENDING',
        question: note,
      };

      if (this.goal) {
        assessmentPayload.goal = this.goal;
      }
      await this.$store.dispatch('assessments/createAssessment', assessmentPayload);
      await this.$store.dispatch('assessments/askEddieForResponse', {
        prompt: JSON.stringify(payload),
        intentId: '669abd8a5b686a5380f26296',
        callback: (decodedChunk) => {
          this.review += decodedChunk;
        },
      });
      const res = JSON.parse(this.review);
      const status = 'CLEARED';
      const rating = parseFloat(res.rating);
      const result = rating >= 8 ? 'PASS' : 'FAIL';
      console.log('xvf', typeof res, res.rating, typeof rating, rating, result);
      // Updated Assessment
      const { goal } = await this.$store.dispatch('assessments/updateAssessment', { status, result });
      if (this.isGameConnected && goal) {
        const response = {
          data: {
            type: 'goal',
            status: 200,
            goal,
          },
        };
        window.parent.postMessage(
          JSON.stringify(response),
          process.env.VUE_APP_GAME_URI,
        );
      }
    },
    addNoteValue(val, goal) {
      this.customLabel = val;
      this.goal = goal;
    },
    // createNote() {
    //   console.log('xvf', this.eddieQuery);
    // },
  },
};
</script>

<style lang="scss" scoped>
// .tjEditor {
//   width: 100%;
//   height: 100%;
//   z-index: 0;

//   form {
//     width: 100%;
//     height: 100%;
//   }
//   .mavonEditor {
//     width: 100%;
//     height: 92%;
//     box-shadow: none!important;;
//   }
//   .button-div {
//     height: 7%;
//   }
// }

.tj-editor {
  width: 100%;
  height: 100%;
  z-index: 0;

  form {
    width: 100%;
    height: 100%;
  }

  &__editor {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    border-radius: 0.4rem;
  }
  &__text {
    width: 100%;
    height: 92%;
    box-shadow: none !important;
  }
  &__notification {
    padding: 0.4rem;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    display: flex;
    margin: 0 1rem;
    color: var(--color-grey-1);
    align-items: center;

    &--icon {
      margin-right: 0.5rem;
      margin-left: auto;
    }
  }
  .button-div {
    height: 7%;
  }

  &__custom-label {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
}

/* Hide the preview panel */
// .mavon-editor .mavon-preview {
//   display: none !important;
// }

// /* Expand the editor to fill the full width */
// .mavon-editor .mavon-editor-content {
//   width: 100% !important;
// }

</style>
